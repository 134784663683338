import React from "react"
import Button from "../components/Button"
import Container from "../components/Container"
import Img from "../components/Img"
import Section from "../components/Section"
import Site from "../components/Site"
import SiteLink from "../components/SiteLink"
import Video from "../components/Video"
import { formatLink } from "../helpers"
import "../css/new.scss"

const PlanAVisitPage = () => {
  const supTitle   = 'We\'re here to help'
  const mainTitle  = 'What you can expect from a typical Sunday at Hillside.'
  const buttonCta  = 'Plan Your Visit →'
  const buttonHref = 'https://yourhillside.churchcenter.com/people/forms/49955'
  const qa         = [
    { img: 'wear',    q: "What should I wear?",                    a: "Most people wear casual weekend attire, but come as you are. We are excited you are visiting, and we want you to be comfortable. In the summer, it's Texas and it gets hot—shorts are welcome!"                                                       },
    { img: 'park',    q: "Where should I park?",                   a: "When you first arrive on campus, turn your hazards on and we will help guide you from there! We've saved you a parking spot right up front, so be sure to look for and follow the “Guest Parking” signs."                                             },
    { img: 'go',      q: "Where do I go after parking?",           a: "From the moment you drive on campus, we want you to feel that we were expecting you. Our Welcome Team ministry volunteers can't wait to guide you through your first visit."                                                                          },
    { img: 'service', q: "What does a service look like?",         a: "Our Sunday services provide a meeting place for us to gather where we worship together, learn from God's Word, and enjoy the community we call home. And yes, there is plenty of fun and learning for the kids, too."                                 },
    { img: 'contact', q: "Should I let you know I'm coming?",      a: <>Yes! <a href="https://yourhillside.churchcenter.com/people/forms/49955" target="_blank" rel="noopener noreferrer">Tell us ahead of time</a> when you plan to come so that a Welcome Team member will be ready to show you around when you arrive.</> },
    { img: 'next',    q: "What's next if I enjoy my first visit?", a: <>If you want to know more about Hillside, we invite you to attend our Go:Discover class offered twice a month. <SiteLink href={formatLink('discover', 'go')}>Read more and sign up here</SiteLink>.</>                                                },
  ]

  return (
    <Site
      title      = "Plan Your Visit"
      className  = "hcc-new hcc-plan-your-visit"
      themeColor = "#faefea"
    >
      <article>
        <Section tag="header" className="hcc-new-video-intro">
          <Container className="hcc-grid">
            <header>
              <b>{supTitle}</b>
              <h1>{mainTitle}</h1>
              <Button label={`${buttonCta}`} href={buttonHref}/>
            </header>
            <Video
              src         = {['https://player.vimeo.com/external/571805493.hd.mp4?s=366ae221d98ed084c73b3ec2ad44a860b51a82a1&profile_id=174']}
              curtain     = "/plan_your_visit/poster.jpg"
              playsinline = {true}
            />
          </Container>
        </Section>
        <Section className="hcc-new-callout">
          <Container>
            <h2>Here's just a few more things you might find helpful&hellip;</h2>
          </Container>
        </Section>
        <Section className="hcc-plan-your-visit-qa">
          <Container tag="ul" className="hcc-grid">
            {qa.map((el, i) =>
              <li key={el.q}>
                <Img
                  src    = {`/plan_your_visit/q-${el.img}.jpg`}
                  alt    = {el.q}
                  width  = {1400}
                  height = {900}
                />
                <div>
                  <h3>{el.q}</h3>
                  <p>{el.a}</p>
                </div>
              </li>
            )}
          </Container>
        </Section>
        <Section className="hcc-new-callout">
          <Container>
            <h2>Thinking about visiting this Sunday? Let us know!</h2>
            <Button label={buttonCta} href={buttonHref}/>
          </Container>
        </Section>
      </article>
    </Site>
  )
}

export default PlanAVisitPage
